<template>
  <div style="height: auto;min-height: 100vh; background-color: #f5f5f5;">
    <div class="header">
      <van-nav-bar :title="$t('AddressManger')" left-arrow @click-left="$router.go(-1)" />
    </div>
    <div class="titleTv">{{ $t('AddAddress') }}</div>
    <div class="addCent">
      <div class="protocolV">
        <span class="protocolTv">{{$t('ChooseAgreement')}}</span>
        <van-radio-group v-model="protocolTxt" direction="horizontal">
          <van-radio checked-color="#f0b82d" name="TRC20">TRC20</van-radio>
          <van-radio checked-color="#f0b82d" name="ERC20">ERC20</van-radio>
          <van-radio checked-color="#f0b82d" name="BTC">BTC</van-radio>
          <van-radio checked-color="#f0b82d" name="ETH">ETH</van-radio>
        </van-radio-group>
      </div>
      <div class="addressV">
        <span class="addressTv">{{ $t('WalletAddress') }}</span>
        <van-field v-model="addressVal" />
      </div>
    </div>
    <div style="margin-left: 1rem; margin-right: 1rem;">
      <div class="submitTv" @click="onSubmitClick">{{ $t('common.submit') }}</div>
    </div>
    <div class="titleTv" style="padding-top: 30px;">{{$t('ListOfWalletAddresses')}}</div>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        :loading-text="$t('jia-zai-zhong')"
        @load="onLoad"
      >
        <div
          style="margin-top: 1rem;"
          v-for="item in list"
          :key="item.id"
          :title="item"
          @click="onItemClick(item)"
        >
          <van-swipe-cell>
            <div class="cardV">
              <span class="cardCode">{{ item.type }}</span>
              <span class="cardName">{{ item.address }}</span>
            </div>
            <template #right>
              <div
                style="padding-right: 1rem; height: 100%; display: flex; align-items: center; justify-content: center;"
              >
                <img
                  style="width: 2rem; height: 2rem;"
                  src="../../assets/img/delete.png"
                  @click.stop="onItemDelClick(item)"
                />
              </div>
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mType:'',
      protocolTxt: "TRC20",
      addressVal: "",
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.mType = this.$route.params.selete;
  },
  methods: {
    async onLoad() {
      this.loading = true;
      const { data } = await this.$http.get("/home/user/userWalletList");
      if (data) {
        if (data.code === 200) {
          this.finished = true;
          this.list = data.data;
        }
      }
      this.loading = false;
    },
    async onSubmitClick() {
      if (!this.addressVal) {
        this.$toast(this.$t('PleaseEnterAddress'));
        return;
      }
      const postform = {
        type: this.protocolTxt,
        address: this.addressVal,
      };
      const { data } = await this.$http.post("/home/user/walletAdd", postform);
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("common.success"));
          this.addressVal = "";
          this.onLoad();
        } else {
          this.$toast.fail(this.$t("common.error"));
        }
      }
    },
    async onItemDelClick(item) {
      const { data } = await this.$http.get(
        "/home/user/walletDel?id=" + item.id
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("common.success"));
          this.onLoad();
        } else {
          this.$toast.fail(this.$t("common.error"));
        }
      }
    },
    async onItemClick(item) {
      if (this.mType == 1) {

      } else {
        this.$route.params.user = JSON.stringify(item);
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.titleTv {
  padding-top: 60px;
  text-align: left;
  padding-left: 1rem;
  color: #363636;
  font-size: 15px;
}
.addCent {
  background-color: #fff;
}
.protocolV {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  margin-top: 10px;
  border-bottom: 0.1px solid #e5e5e5;
  .protocolTv {
    color: #363636;
    font-size: 15px;
    padding-right: 20px;
  }
}

.addressV {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}

.addressTv {
  color: #363636;
  font-size: 15px;
  padding-right: 20px;
}

.submitTv {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #fff;
  height: 2.75rem;
  width: 100%;
  background: #f0b82d;
}

.van-cell {
  flex: 1;
  padding: 0;
}
.cardV {
  height: 3.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cardCode {
    color: #323232;
    font-size: 15px;
    text-align: left;
  }

  .cardName {
    color: #999;
    font-size: 10px;
    margin-top: 0.2rem;
    text-align: left;
  }
}
/deep/.van-field__body{
  border-bottom: 0.1px solid #e5e5e5;
}
</style>
